import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import moment from "moment-timezone"

import {
  getMe,
  validateIfAvailableToPurchase,
} from "@repository/UserRepository"
import { getToken } from "@src/http/client"
import CountDown from "@components/CountDown"
import {
  detectMob,
  useInput,
  EVENT_START_TIME,
  EVENT_END_TIME,
} from "@src/Helper"
import "../../styles/_events.scss"

function EXMChristmas() {
  const [user, setUser] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isAvailableFromServer, setIsAvailableFromServer] = useState(false)

  const images = useStaticQuery(graphql`
    {
      exmBeforeText: file(
        relativePath: { eq: "icons/mafia-bday-before-text-mobile.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      exmAfterText: file(
        relativePath: { eq: "icons/mafia-bday-after-text-mobile.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const [packages] = useState([
    {
      code: "EXTADS001",
      name: "1-Week Ads",
      description: "Advertise for a week full",
      price: "2.99 USD",
    },
    {
      code: "EXTADS002",
      name: "2-Week Ads",
      description: "Advertise for 2 weeks full",
      price: "4.99 USD",
    },
    {
      code: "EXTADS003",
      name: "1-Month Ads",
      description: "Advertise for a month full",
      price: "7.99 USD",
    },
  ])

  const handleGetMe = () => {
    getMe()
      .then(res => {
        setUser(res.data)
        setIsLoggedIn(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const formatProductCode = productCode => {
    return JSON.stringify({
      user_id: user.id,
      product_code: productCode,
    })
  }

  const renderSubscribeButton = packageId => {
    if (isLoggedIn && user !== null) {
      switch (packageId) {
        case "EXTADS001":
          return (
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              className="paypal-button"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="E99DLW3D9LZYL"
              />
              <input
                type="hidden"
                name="custom"
                value={formatProductCode("EXTADS001")}
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribe_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          )
        case "EXTADS002":
          return (
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              className="paypal-button"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="24MMDVDG77QLG"
              />
              <input
                type="hidden"
                name="custom"
                value={formatProductCode("EXTADS002")}
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribe_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          )
        case "EXTADS003":
          return (
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              className="paypal-button"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="LYBFYEV5TBU3G"
              />
              <input
                type="hidden"
                name="custom"
                value={formatProductCode("EXTADS003")}
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribe_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          )
        default:
          return <></>
      }
    } else {
      return (
        <button
          className="button button-sm button-event-checkout button-mafia-danger"
          onClick={() => {
            navigate("/signin")
          }}
        >
          Subscribe
        </button>
      )
    }
  }

  useEffect(() => {
    navigate('/404')
    validateIfAvailableToPurchase().then(res => {
      setIsAvailableFromServer(res.data.is_valid)
    })
  }, [])

  useEffect(() => {
    if (getToken() !== null) {
      handleGetMe()
    } else {
      setIsLoggedIn(false)
    }
    if (!detectMob()) {
      navigate('/');
    }
  }, [])

  return (
    <div id="m-exm-event" className="container section-90">
      <h2 className="subtitle text-700 text-center mb-0">
        Promote Your<br/>Products & Services with
      </h2>
      <h1 className="heading text-monday-blue text-center mb-0">
        Executive Mafia
      </h1>
      <Img
        className="exm-handwriting mt-3"
        fluid={
          new Date().getTime() < EVENT_START_TIME
            ? images.exmBeforeText.childImageSharp.fluid
            : images.exmAfterText.childImageSharp.fluid
        }
        alt="Executive Mafia Birthday Event."
      />
      <CountDown />
      <p className="body mb-0">
        The Year-End package deal is none other than the <span className="text-monday-blue text-700">Ads Package</span>.
        Through <span className="text-monday-blue text-700">EXM's Ads Room</span>, you can advertise your products/services without having to subscribe to a core-subscription package.<br/><br/>
        You can also choose the preferable length of duration for the <span className="text-monday-blue text-700">Ads Package</span> you want.
      </p>
      <div className="row">
        {packages.map((p, i) => {
          return (
            <div className="col-12 christmas-card-col mt-3">
              <div className="christmas-card">
                <p className="title text-700 mb-1 text-pure-white">
                  {p.name}
                </p>
                <p className="caption mb-3 text-pure-white">{p.description}</p>
                <div className="price-tag d-flex">
                  <div className={`${isAvailableFromServer ? 'col-6' : 'ml-auto'} my-auto`}>
                    <p className={`text-golden-yellow mb-0 ${isAvailableFromServer ? 'heading' : 'large-title'} `}>{p.price}</p>
                  </div>
                  {isAvailableFromServer && (
                    <div className="col-6">{renderSubscribeButton(p.code)}</div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="tnc body">
        <p><span className="mdi mdi-information mdi-18px align-middle text-monday-blue mt-4" /> Terms and Condition</p>
        <ul>
          <li>The Ads Package can only be purchased on <span className="text-700 text-monday-blue">December 24th 2020 -  January 2nd 2021</span></li>
          <li>The subscription system is <span className="text-700 text-monday-blue">recurring</span>, so you will be charged periodically according to the <span className="text-700 text-monday-blue">length of duration</span> you subscribed to.</li>
          <li>The <span className="text-700 text-monday-blue">Ads Package</span> gives you access to <span className="text-700 text-monday-blue">1 ads slot</span> per subscription.</li>
          <li>If you <span className="text-700 text-monday-blue">un-subscribe</span>, you won't be able to purchase the <span className="text-700 text-monday-blue">Ads Package</span> anymore since they will no longer be available.</li>
        </ul>
      </div>
    </div>
  )
}

export default EXMChristmas
